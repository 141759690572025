<template>
  <div
    v-click-outside="hideTooltip"
    class="tooltip-custom"
    :class="{ active: isShow }"
    @click="isShow = true"
  >
    <CommonBaseImg
      class="icon-tooltip"
      :src="iconUrl"
      alt="icon tooltip"
      @click="isShow = true"
    />
    <div
      v-if="text"
      class="text-tooltip"
    >
      {{ text }}
    </div>
    <div class="tooltip-content">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import CommonBaseImg from '~/components/common/base-img'

defineProps({
  iconUrl: {
    type: String,
    default: '/assets/images/components/common/help.svg'
  },
  text: {
    type: String,
    default: ''
  }
})

const isShow = ref(false)

watch(isShow, () => {
  if (isShow.value) {
    setTimeout(() => {
      isShow.value = false
    }, 3000)
  }
})

const hideTooltip = () => {
  isShow.value = false
}

onMounted(() => {
  window.addEventListener('scroll', () => (isShow.value = false))
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', () => (isShow.value = false))
})
</script>
<style lang="scss" scoped src="assets/scss/components/common/base-tooltip.scss" />
